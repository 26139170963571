<template>
  <b-modal
    v-model="isShowModal"
    centered
    hide-footer
    hide-header-close
    hide-header
    @hide="onHide"
  >
    <VueSlickCarousel v-if="imageList.length > 0" :arrows="true">
      <div
        v-for="(item, index) in imageList"
        :key="index"
        class="position-relative"
      >
        <img
          v-if="isDeleteImg"
          class="trash-icon"
          src="../../assets/icon/trash.png"
          @click="confirmDelete(item.id)"
        />
        <img :src="item.url" />
      </div>
    </VueSlickCarousel>
    <confirmModal
      ref="confirm-modal"
      :title="`Confirm Delete`"
      :content="`Are you sure you want to delete image?`"
      @handleCloseModal="handleCloseModal"
    />
  </b-modal>
</template>
<script>
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';
import UserServices from '@/services/user.service.js';
export default {
  name: 'ImageModal',
  components: {
    VueSlickCarousel,
    confirmModal: () => import('@/components/modals/confirm-modal.vue'),
  },
  props: {
    imgList: {},
    isDeleteImg: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      imageList: [],
      isShowModal: false,
      currentID: '',
      isUpdateImage: false,
    };
  },
  methods: {
    openModal() {
      this.isShowModal = true;
    },
    closeModal() {
      this.isShowModal = false;
    },
    confirmDelete(id) {
      this.currentID = id;
      this.$refs['confirm-modal'].openModal();
    },
    handleCloseModal($event) {
      if ($event === 'yes') {
        const param = {
          id_list: [this.currentID],
        };

        UserServices.deleteImages(param).then(() => {
          const idx = this.imageList.findIndex((item) => {
            return item.id === this.currentID;
          });
          this.imageList.splice(idx, 1);
          if (this.imageList.length === 0) {
            this.closeModal();
          }
          this.isUpdateImage = true;
        });
      }
    },
    onHide() {
      if (this.isUpdateImage) {
        this.$emit('updatedImageList');
      }
    },
  },
  mounted() {
    this.imageList = this.imgList;
  },
  watch: {
    imgList(value) {
      this.imageList = value;
    },
  },
};
</script>
<style lang="scss" scoped>
.trash-icon {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
}
</style>
